<template>
  <div class="modal-wrapper">
    <div class="modal">
      <!-- <button class="btn btn-outline-white btn-circle" @click="close">
        <i class="fa fa-close"></i>
      </button> -->

      <div class="modal-header with-icon">
        <div class="icon" :class="iconClass">
          <img :src="`/imgs/svg/icon_${icon}.svg`" alt="" />
        </div>
      </div>
      <div class="modal-body">
        <slot />
        <ContactForm @onSent="close" :download="download" />
      </div>
    </div>
  </div>
</template>
<script>
import ContactForm from "./ContactForm.vue";

export default {
  components: {
    ContactForm,
  },
  props: {
    callback: {
      type: Function,
      default: () => ({}),
    },
    icon: {
      type: String,
      default: "chat",
    },
    iconClass: {
      type: String,
      default: "icon-green-grad",
    },
    download: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    document.body.style = "overflow: hidden;";
  },
  beforeUnmount() {
    document.body.style = "";
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style></style>
