<template>
  <footer class="footer">
    <div class="container">
      <div class="card card-green-grad card-brochure" v-if="!showBrochure">
        <div class="card-header bg-blue-grad-2">
          <ImageContainer fileName="brochure" />
        </div>
        <div class="card-body text-center py-5 pointer" @click="showModal = true">
          <h3>{{ $lang("footer.title", true) }}</h3>
          <p>{{ $lang("footer.text", true) }}</p>
        </div>
      </div>

      <div class="row align-items-center mb-2">
        <div class="col-md-6 mb-4 mb-md-0 mt-5">
          <img src="/nedcollege/NEDCOLLEGE_logo_light.svg" width="200" alt="" />
        </div>
        <div class="col-md-3 offset-md-3 mb-4 mb-md-0">
          <div class="btn-group">
            <a
              href="https://www.instagram.com/nedcollege/"
              target="_blank"
              class="btn btn-sm btn-circle btn-blue2"
            >
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a
              href="https://www.facebook.com/NEDCollege/"
              target="_blank"
              class="btn btn-sm btn-circle btn-blue2"
            >
              <i class="fa-brands fa-facebook"></i>
            </a>
            <a
              href="https://www.youtube.com/user/nedtcschool"
              target="_blank"
              class="btn btn-sm btn-circle btn-blue2"
            >
              <i class="fa-brands fa-youtube"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/nedcollege"
              target="_blank"
              class="btn btn-sm btn-circle btn-blue2"
            >
              <i class="fa-brands fa-linkedin"></i>
            </a>
            <a
              href="https://www.tiktok.com/@nedcollege"
              target="_blank"
              class="btn btn-sm btn-circle btn-blue2"
            >
              <i class="fa-brands fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-3 d-lg-flex flex-column mb-5 mt-md-4 m-lg-0">
          <div class="mt-auto">
            <p class="text-green3"><b>Dublin Campus</b></p>
            <p>Dominick Street Lower</p>
            <p>Dublin 1 - D01 P9P4</p>
            <p>{{ $lang("footer.ireland", true) }}</p>
            <p>+353 1 878 3047</p>
            <p>dublin@ned.ie</p>

            <hr />

            <div class="download-btn-container">
              <small class="text-green3">
                <b>{{ $lang("footer.download_d", true) }}</b>
              </small>

              <div class="btn-group">
                <a
                  href="https://apps.apple.com/us/app/ned-dublin/id1641756117"
                  target="_blank"
                  class="btn btn-circle btn-sm btn-blue1"
                >
                  <i class="fa-brands fa-apple"></i>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.kmpus.app.neddublin"
                  target="_blank"
                  class="btn btn-circle btn-sm btn-blue1"
                >
                  <i class="fa-brands fa-google-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 d-lg-flex flex-column mb-5 mt-md-4 m-lg-0">
          <div class="mt-auto">
            <p class="text-blue3"><b>Limerick Campus</b></p>
            <p>Chapel House</p>
            <p>Limerick - V94 YH6Y</p>
            <p>{{ $lang("footer.ireland", true) }}</p>
            <p>+353 (0) 61468571</p>
            <p>limerick@ned.ie</p>

            <hr />

            <div class="download-btn-container">
              <small class="text-blue3">
                <b>{{ $lang("footer.download_l", true) }}</b>
              </small>

              <div class="btn-group">
                <a
                  href="https://apps.apple.com/us/app/ned-limerick/id1643192431"
                  class="btn btn-circle btn-sm btn-blue1"
                >
                  <i class="fa-brands fa-apple"></i>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.kmpus.app.neddublin"
                  target="_blank"
                  class="btn btn-circle btn-sm btn-blue1"
                >
                  <i class="fa-brands fa-google-play"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 offset-md-3 offset-lg-3 col-lg-3 text-md-center text-lg-left">
          <p>
            <b>{{ $lang("footer.opening", true) }}</b>
          </p>
          <p>
            {{ $lang("footer.days", true) }} <br />
            {{ $lang("footer.days1", true) }}
          </p>
          <hr />
          <p>
            <a
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=p6p2U1WTKEiv7JiYHdJ8HzttLjrfemxMiGxgjvXXKEhUMVpIQTdQSUpPUExMWUdIUkQ2TVBMU0cyVi4u"
              target="_blank"
            >
              {{ $lang("footer.refund", true) }}
            </a>
          </p>
          <p>
            <router-link :to="{ name: 'terms_and_conditions' }">{{
              $lang("footer.terms", true)
            }}</router-link>
          </p>
          <p>
            <router-link :to="{ name: 'privacy_policy' }">{{
              $lang("footer.privacy", true)
            }}</router-link>
          </p>
          <p>
            <router-link :to="{ name: 'careers' }">{{ $lang("careers.title", true) }}</router-link>
          </p>
          <hr />
          <div class="download-btn-container">
            <a href="#" @click.prevent="showModal = true">
              <small>
                <b>{{ $lang("footer.download", true) }}</b>
              </small>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <FormModal v-if="showModal" @close="showModal = false" download>
    <div class="text-center mb-5">
      <h3 class="text-blue2">{{ $lang("footer.download", true) }}</h3>
      <h5 class="fw-regular">{{ $lang("footer.subtitlemodal", true) }}</h5>
    </div>
  </FormModal>
</template>

<script>
import FormModal from "./FormModal";

export default {
  components: {
    FormModal,
  },
  data: () => ({
    showModal: false,
  }),
  computed: {
    showBrochure() {
      return !this.$route.meta.no_brochure;
    },
  },
};
</script>
