export default [
  {
    title: "Courses",
    to: { name: "courses" },
    children: [],
  },
  {
    title: "Our Campuses",
    to: null,
    children: [
      {
        title: "Dublin Campus",
        to: { name: "campus_dublin" },
      },
      {
        title: "Limerick Campus",
        to: { name: "campus_limerick" },
      },
    ],
  },
  {
    title: "About Us",
    to: null,
    children: [
      {
        title: "NED College",
        to: { name: "ned_college" },
      },
      {
        title: "Associates & Accreditations",
        to: { name: "accreditations" },
      },
      {
        title: "Privacy Policy",
        to: { name: "privacy_policy" },
      },
      {
        title: "Terms and conditions",
        to: { name: "terms_and_conditions" },
      },
      {
        title: "Careers",
        to: { name: "careers" },
      },
    ],
  },
  {
    title: "Student Life",
    to: null,
    children: [
      {
        title: "Services",
        to: { name: "services" },
      },
      {
        title: "Student Visa",
        to: { name: "student_visa" },
      },
    ],
  },
  {
    title: "FAQs",
    to: { name: "faqs" },
    children: [],
  },
  {
    title: "Contact us",
    to: { name: "contact_us" },
    children: [],
  },
];
