<template>
  <div class="topbar">
    <div class="container">
      <div class="brand">
        <router-link to="/">
          <img src="/nedcollege/NEDCOLLEGE_logo_light.svg" alt="" />
        </router-link>
      </div>
      <nav id="navbar">
        <ul class="list-unstyled">
          <li v-for="(m, i) of menus" :key="i" :class="{ with_submenu: m.children.length > 0 }">
            <router-link :to="m.to" v-if="m.to">
              {{ m.title }}
            </router-link>
            <span v-else>
              {{ m.title }}
              <i class="fa fa-angle-down" v-if="m.children.length" />
            </span>

            <ul v-if="m.children.length">
              <li v-for="(sm, smi) of m.children" :key="smi">
                <router-link :to="sm.to" v-if="sm.to">
                  {{ sm.title }}
                </router-link>
                <span v-else>{{ sm.title }}</span>
              </li>
            </ul>
          </li>
          <li>
            <router-link :to="{ name: 'contact_us' }">
              <button class="btn btn-green-grad">
                {{ $lang("topbar.button", true) }}
              </button>
            </router-link>
          </li>
          <li class="with_submenu">
            <button class="btn btn-circle btn-sm btn-blue-grad-1">
              {{ $route.params.lang.toUpperCase() }}
            </button>

            <ul>
              <!-- Deactivate languages temporarly while mkt dept checks the files -->
              <!-- <li v-for="(lang, li) of ['en', 'es', 'pr']" :key="li"> -->
              <li v-for="(lang, li) of ['en']" :key="li">
                <router-link :to="{ name: $route.name, params: { ...$route.params, lang } }">
                  {{ lang.toUpperCase() }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <div class="close-button" @click="toggleNavbar">
          <i class="fa fa-close"></i>
        </div>
      </nav>
      <div class="collapse-button" @click="toggleNavbar">
        <i class="fa fa-bars"></i>
      </div>
    </div>
  </div>
</template>

<script>
const toggleNavbar = () => {
  const navbar = document.querySelector("#navbar");

  if (navbar && navbar.classList.contains("open")) {
    navbar.classList.remove("open");
  } else {
    navbar.classList.add("open");
  }
};

const onMenuClick = () => {
  const navbar = document.querySelector("#navbar");

  if (navbar && navbar.classList.contains("open")) {
    navbar.classList.remove("open");
  }
};

import menus from "@/router/menus.js";

export default {
  data: () => ({
    menus,
  }),
  methods: {
    toggleNavbar,
  },
  mounted() {
    const navs = document.querySelectorAll("#navbar ul li:not(.with_submenu)");
    navs.forEach((x) => x.addEventListener("click", onMenuClick));
  },
  beforeUnmount() {
    const navs = document.querySelectorAll("#navbar ul li:not(.with_submenu)");
    navs.forEach((x) => x.removeEventListener("click", onMenuClick));
  },
};
</script>
