<template>
  <Topbar />

  <main class="text-center text-md-left">
    <router-view />
  </main>

  <Footer />
</template>

<script>
import Topbar from "@/components/Topbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Topbar,
    Footer,
  },
};
</script>
